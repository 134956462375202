<template>
  <div class="user-setting">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ 'dash.user.setting.title' | text }}</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <filter-user @setFilterData="setFilterData" />
        </v-card>
         <v-card
          rounded="lg"
          class="pa-9 mt-4"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <div class="d-flex justify-end">
            <div class="">
              <v-btn 
                color="primary"
                :min-width="160"
                type="button"
                large
                @click="openDetail('add')"
              >
                TAMBAH
              </v-btn>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="userList"
            :loading="loading"
            :items-per-page="10"
            :footer-props="{'items-per-page-options':[10, 25, 50, 100]}"
            class="elevation-2 mt-4"
            @update:options="updateOpt"
          >
           <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                small
                color='primary'
                :min-width="120"
                @click="openDetail('edit', item)"
              >
                PERBARUI
              </v-btn>
              <v-btn
                small
                color='error'
                :min-width="120"
                class="ml-2"
                @click="openDialogConfirm(item)"
              >
                HAPUS
              </v-btn>
            </template>
          </v-data-table>
          <div class="d-flex justify-end pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
              @input="changeListPage"
            ></v-pagination>
          </div>
        </v-card>
        <dialog-info ref="dialogInfoSuccess" :info="infoSuccess"/>
        <dialog-confirm ref="dialogConfirmRemoveUser" :info="info" :action="handleRemove"/>
      </div>
    </v-container>
  </div>
</template>

<script>
import {get} from 'dot-prop'
import FilterUser from '@/apps/dashboard/components/filters/filterUserSetting'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'
import {
  ADD_USER
} from '@/constants/pages'
import {
  API_DASH_GET_USER_LIST,
  API_DASH_REMOVE_USER
} from '@/constants/apis'
import loadscreen from '@/utils/common/loadScreen'
import request from '@/utils/request'
  export default {
    name: 'UserSetting',
    components: {
      FilterUser,
      DialogConfirm,
      DialogInfo
    },
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        headers: [
          {
            text: 'Nama Pengguna',
            align: 'start',
            value: 'name',
            class: 'black--text subtitle-2'
          },
          { text: 'E-mail', value: 'email', class: 'black--text subtitle-2' },
          { text: 'Nomor HP', value: 'phone', class: 'black--text subtitle-2' },
          { text: 'Peran', value: 'role', class: 'black--text subtitle-2' },
          { text: 'Detail', value: 'actions', sortable: false, class: 'black--text subtitle-2' }
        ],
        userList: [],
        totalList: 0,
        loading: true,
        options: {},
        limit: 10,
        page: 1,
        pageCount: 1,
        info: {
          desc: 'Apakah anda yakin menghapus?'
        },
        filterData: {
          name: '',
          role: 'Semua',
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.submit.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    created() {
      this.getDataFromApi()
    },
    methods: {
      updateOpt (e) {
        if (e.itemsPerPage !== this.limit) {
          this.limit = e.itemsPerPage
          this.setFilterData(this.filterData)
        }
      },
      setFilterData (params) {
        this.filterData.name = params.name
        this.filterData.role = params.role === 'Semua' ? '' : params.role
        this.page = 1
        this.getDataFromApi()
      },
      getDataFromApi () {
        this.loading = true
        const params = {}
        params.page = this.page
        params.limit = this.limit
        if (this.filterData.name) params.name = this.filterData.name
        if (this.filterData.role && this.filterData.role !== 'Semua') params.role = this.filterData.role
        request(API_DASH_GET_USER_LIST, params).then(res => {
          if (res.success) {
            this.userList = res.list
            this.pageCount = res.pagination.total_page
            this.loading = false
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
      },
      changeListPage () {
        this.getDataFromApi()
      },
      openDetail (source, item = {}) {
        if (source === 'edit') {
          this.$store.dispatch('dashboard/setUserData', item)
        }
        this.$router.push({
          name: ADD_USER,
          query: {
            source
          }
        }).catch(() => {})
      },
      openDialogConfirm (item) {
        this.$refs.dialogConfirmRemoveUser && this.$refs.dialogConfirmRemoveUser.show()
        this.selectedItem = item
      },
      handleRemove () {
        request(API_DASH_REMOVE_USER, this.selectedItem).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
            this.getDataFromApi()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
        
      }
    },
  }
</script>

<style lang="scss" scoped>
.user-setting {

}
</style>